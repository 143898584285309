import React, { useState } from 'react';
import './TirumalaDC.css';
import axios from 'axios';
import { useEffect } from 'react';
import logoImage from '../logo.webp';
import SuccessModal from '../Modals/SuccessModal/SuccessModal';
import ErrorModal from '../Modals/ErrorModal/ErrorModal';
import ClockLoader from "react-spinners/ClockLoader";
import { branchList } from '../LenderBranchLists/LenderBranchLists';


const TirumalaDC = () => {
  const override: CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", // This centers the element
    display: "block",
    margin: "auto", // Centering in the parent container
    borderColor: "red", // Border color for debugging
  };
  
  const handleSuccessClose = () => setDataIsSent(false);
  const handleErrorClose = () => setDataIsNotSent(false);
const[count,setCount] = useState(1);
const[totalLoanAmount,setTotalLoanAmount] = useState(0);
const [loanEntries, setLoanEntries] = useState([
  { loanAmount: '', loanSanctionDate: '', loanEndDate: '', loanType: '', lenderBranch: '',loanAccountNumber:'' }
]);
const[loading,setLoading] = useState(false);
const[popupResponse,setPopupResponse] = useState('');
const[ErrorResponse,setErrorResponse] = useState('');
const[ageLimitExceed,setAgeLimitExceed] = useState(false);
const handleLoanChange = (e, index) => {
  const { name, value } = e.target;
  
  // Extract the field name without the index
  const [fieldName, fieldIndex] = name.split('_');

  // Ensure index is correct
  if (parseInt(fieldIndex, 10) === index) {
    const updatedEntries = [...loanEntries];
    updatedEntries[index] = { ...updatedEntries[index], [fieldName]: value };
    setLoanEntries(updatedEntries);

    const newTotalLoanAmount = updatedEntries.reduce((total, entry) => {
      // Convert to number or default to 0 if invalid
      const loanAmount = parseFloat(entry.loanAmount) || 0;
      return total + loanAmount;
    },0);
    // Update the total loan amount state
    setTotalLoanAmount(newTotalLoanAmount);
  }
  
};
const addMoreLoans = (e) => {
  e.preventDefault()
  if (count < 10) {
    setLoanEntries([...loanEntries, { loanAmount: '', loanSanctionDate: '', loanEndDate: '', loanType: '', lenderBranch: '',loanAccountNumber:'' }]);
    setCount(count + 1);
  }
};
  const validateEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
};
const validatePhoneNumber = (phoneNumber) => {
  const phonePattern = /^[6-9][0-9]{9}$/;
  return phonePattern.test(phoneNumber);
};
const validatePincode = (pincode) => {
  const pincodePattern = /^[1-9][0-9]{5}$/;
  return pincodePattern.test(pincode);
};

  const[dataIsSent,setDataIsSent] = useState(false);
  const[dataIsNotSent,setDataIsNotSent]= useState(false);
  const [pricingData, setPricingData] = useState({});

  const [cvgSum, setCvgSum] = useState('');
  const[tenure,setTenure] = useState('');
  const [coverage, setCoverage] = useState('');
 const[premiumExcGST,setPremiumExcGST] = useState('');
 
  
  useEffect(() => {
    const fetchPricingData = async () => {
      try {
        const response = await axios.get('https://script.google.com/macros/s/AKfycbxIEEkqQZgqPp-JLQeEiMXDiPm8GFCO8AZd3-cMYu9jv97RHTat4rljSUiTsDZTGeSV/exec');
        const transformedData = transformPricingData(response.data);
        setPricingData(transformedData);
        if (Object.keys(transformedData).length > 0) {
          setTenure(Object.keys(transformedData)[0]);
        }
      } catch (error) {
        console.error('Error fetching pricing data:', error);
      }
    };

    fetchPricingData();
  }, []); 
 
  const transformPricingData = (data) => {
    const result = {};
    data.forEach(item => {
      const years = item['Insurance coverage (In years)'];
      delete item['Insurance coverage (In years)'];
      result[years] = item;
    });
    return result;
  };
  useEffect(()=>{
   if(totalLoanAmount){
    const amounts = Object.keys(pricingData[tenure]).map(Number).sort((a, b) => a - b);
    let closestAmount = amounts.find(amount => amount >= totalLoanAmount);
    if (!closestAmount) {
      closestAmount = amounts[amounts.length - 1];
    }
    setCoverage(closestAmount);
   }
  },[totalLoanAmount,tenure])

  useEffect(() => {
    if (totalLoanAmount) {
      const finalPremium = calculatePremium(totalLoanAmount, tenure);
      setFormData({
        ...formData,
        premiumIncGST: finalPremium,
      });
    }
  }, [totalLoanAmount, tenure]);

  const calculatePremium = (loanAmount, tenure) => {
    // const transformedTenure = `${tenure}_years`
    if (!pricingData[tenure]) {
      return 'N/A';
    }
  
    const amounts = Object.keys(pricingData[tenure]).map(Number).sort((a, b) => a - b);
    let closestAmount = amounts.find(amount => amount >= loanAmount);
    if (!closestAmount) {
      closestAmount = amounts[amounts.length - 1];
    }
    // setCoverage(closestAmount);
    const basePremium = pricingData[tenure][closestAmount];
    let amountWithoutCommas = basePremium.toString().replace(/,/g, "");
    let amountNumber = parseFloat(amountWithoutCommas);
    setPremiumExcGST(amountNumber);
    const premiumWithTax = (amountNumber + (amountNumber * 0.18)).toFixed(3);
    const roundedUpPremium = Math.ceil(premiumWithTax);
    return roundedUpPremium;
  };
  
  const [formData, setFormData] = useState({
    aadharFrontImage: null,
    aadharBack:null,
    panImage: null,
    customerId: '',
    dob: '',
    age: '',
    firstName: '',
    lastName: '',
    gender: '',
    address: '',
    pincode: '',
    mobileNumber: '',
    emailId: '',
    memberAadharNo: '',
    memberPANCard: '',
    nomineeDetailsName: '',
    nomineeRelationship: '',
    nomineeAadharNo: '',
    nomineePanNo:'',
    state: '',
    memberOccupation: '',
    premiumIncGST: '',
  });
  const requiredFields = [
    'customerId',
    'firstName',
    'lastName',
    'dob',
    'age',
    'gender',
    'address',
    'pincode',
    'mobileNumber',
    'emailId',
    'state',
  ];
  const isCustomerIdValid = () => formData.customerId.trim() !== '';
  const isFirstNameValid = () => formData.firstName.trim() !== '';
  const isLastNameValid = () => formData.lastName.trim() !== '';
  const isDobValid = () => formData.dob !== '';
  const isAgeValid = () => formData.age !== '';
  const isGenderValid = () => formData.gender.trim() !== '';
  const isAddressValid = () => formData.address.trim() !== '';
  const isPincodeValid = () => validatePincode(formData.pincode);
  const isMobileNumberValid = () => validatePhoneNumber(formData.mobileNumber);
  const isEmailIdValid = () => validateEmail(formData.emailId);
  const isStateValid = () => formData.state.trim() !== '';
  const areRequiredFieldsFilled = () => {
    return (
        isCustomerIdValid() &&
        isFirstNameValid() &&
        isLastNameValid() &&
        isDobValid() &&
        isAgeValid() &&
        isGenderValid() &&
        isAddressValid() &&
        isPincodeValid() &&
        isMobileNumberValid() &&
        isEmailIdValid() &&
        isStateValid()&&
        !ageLimitExceed
    );
};

  const [imagePreviews, setImagePreviews] = useState({
    aadharFrontImage: null,
    aadharBack:null,
    panImage: null,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDobChange = (e) => {
    const dob = e.target.value;
    const age = calculateAge(new Date(dob));
    if(age>65){
      setAgeLimitExceed(true);
    }else{
      setAgeLimitExceed(false);
    }
    setFormData({
      ...formData,
      dob,
      age,
    });
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };
  const calculateExpiryDate = (tenure) => {
    const currentDate = new Date(); // Get the current date
    currentDate.setFullYear(currentDate.getFullYear() + parseInt(tenure)); // Add tenure years
    const year = String(currentDate.getFullYear()); // Get the last two digits of the year (YY)
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so +1, and pad single digit months
    const day = String(currentDate.getDate()).padStart(2, '0'); // Pad single digit days
  
    return `${year}-${month}-${day}`; // Format as "YY/MM/DD"
  };
  const calculateIssueDate = () => {
    const currentDate = new Date(); // Get the current date
    currentDate.setFullYear(currentDate.getFullYear()); 
    const year = String(currentDate.getFullYear()); // Get the last two digits of the year (YY)
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so +1, and pad single digit months
    const day = String(currentDate.getDate()).padStart(2, '0'); // Pad single digit days
  
    return `${year}-${month}-${day}`; // Format as "YY/MM/DD"
  };

  const validateForm = () => {
    const newErrors = {};
  
    // Check for empty fields
    Object.keys(formData).forEach(key => {
      if (formData[key] === '' && key !== 'nomineePanNo') {
        newErrors[key] = 'This field is required';
      }
    });
  
    // Additional specific checks
    if(formData.mobileNumber && !validatePhoneNumber(formData.mobileNumber)){
      newErrors.mobileNumber = 'Invalid Mobile Number';
    }
    if (formData.emailId && !validateEmail(formData.emailId)) {
      newErrors.emailId = 'Invalid Email Address';
    }
    if (formData.pincode && !validatePincode(formData.pincode)) {
      newErrors.pincode = 'Invalid Pincode';
    }
    if (formData.memberAadharNo && !validateAadhaar(formData.memberAadharNo)) {
      newErrors.memberAadharNo = 'Invalid Aadhaar Number';
    }
    if (formData.nomineeAadharNo && !validateAadhaar(formData.nomineeAadharNo)) {
      newErrors.nomineeAadharNo = 'Invalid Aadhaar Number';
    }
    // if (formData.nomineePanNo && !validatePAN(formData.nomineePanNo)) {
    //   newErrors.nomineePanNo = 'Invalid PAN Number';
    // }
    if (formData.memberPANCard && !validatePAN(formData.memberPANCard)) {
      newErrors.memberPANCard = 'Invalid PAN Number';
    }
   

    //loan-errors 
    loanEntries.forEach((entry, index) => {
      if (!entry.loanAmount) {
        newErrors[`loanAmount_${index}`] = 'Loan Amount is required';
      }
      if (!entry.loanSanctionDate) {
        newErrors[`loanSanctionDate_${index}`] = 'Loan Sanction Date is required';
      }
      if (!entry.loanAccountNumber) {
        newErrors[`loanAccountNumber_${index}`] = 'Loan Account Number is required';
      }
      
      if (!entry.loanEndDate) {
        newErrors[`loanEndDate_${index}`] = 'Loan End Date is required';
      }
      if (!entry.loanType) {
        newErrors[`loanType_${index}`] = 'Loan Type is required';
      }
      if (!entry.lenderBranch) {
        newErrors[`lenderBranch_${index}`] = 'Lender Branch is required';
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm() || ageLimitExceed) {
        return; // If validation fails, do not proceed with submission
    }
    setLoading(true);
    // Function to convert file to Base64
    const toBase64 = (file) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
    function fileToBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(new Uint8Array(reader.result));
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });
    }
    // Create a copy of formData to avoid directly mutating the state
    const transformedTenure = tenure.split('_')[0];
     const source = "form";
     const batch = "FA-42"
     const org_name = "Tirumalla Tirupati Multistate co-op credit society ltd";
     const org_id = '66bee4e6a4a176f9b467df5f';
     const fullname = `${formData.firstName} ${formData.lastName}`;
     const expiryDate = calculateExpiryDate(transformedTenure);
     const issueDate = calculateIssueDate();
    let formDataToSend = { ...formData,coverage,tenure:transformedTenure,premiumExcGST,loans: loanEntries,cvgSum,totalLoanAmount,source,batch,org_id,org_name,fullname,expiryDate,issueDate};
    try {
      // Convert the images to Base64 and add to formDataToSend
      if (formData.aadharFrontImage) {
        formDataToSend.aadharFrontImage = await toBase64(formData.aadharFrontImage);
      }
      if(formData.aadharBack){
        formDataToSend.aadharBack = await toBase64(formData.aadharBack);
      }
      if (formData.panImage) {
        formDataToSend.panImage = await toBase64(formData.panImage);
      }
  
      // console.log(formDataToSend,"this is formData to send") 
      const url = 'https://flashaid-v2-backend-7k5qcren2q-uc.a.run.app/api/v2/user/tirumalalead';
        // const url = 'http://localhost:9040/api/v2/user/tirumalalead'; 
      const response = await axios.post(url, formDataToSend);
      setPopupResponse(response.data.data.results[0]);
      setDataIsSent(true);
      setLoading(false);
      setFormData({
        aadharFrontImage: null,
        aadharBack:null,
        panImage: null,
        customerId: '',
        dob: '',
        age: '',
        firstName: '',
        lastName: '',
        gender: '',
        address: '',
        pincode: '',
        mobileNumber: '',
        emailId: '',
        memberAadharNo: '',
        memberPANCard: '',
        nomineeDetailsName: '',
        nomineeRelationship: '',
        nomineeAadharNo: '',
        nomineePanNo:'',
        state: '',
        memberOccupation: '',
        premiumIncGST: '',
      });
      setLoanEntries([
        { loanAmount: '', loanSanctionDate: '', loanEndDate: '', loanType: '', lenderBranch: '',loanAccountNumber:'' }
      ]);
    setCurrentPage(1);
    setCvgSum('');
    setCoverage('');
    setPremiumExcGST('');
    setImagePreviews({
      aadharFrontImage: null,
      aadharBack:null,
      panImage: null,
    });
    setTotalLoanAmount(0);
    } catch (error) {
      setErrorResponse(error.response.data.data.message)
      console.error('Error:', error,"new error");
      setDataIsNotSent(true);
      setLoading(false);
      setTimeout(() => {
        setDataIsNotSent(false);
      }, 2000);
    }
  };
  
  const validateAadhaar = (aadhaar) => {
    const expr = /^([0-9]{4}[0-9]{4}[0-9]{4}$)|([0-9]{4}\s[0-9]{4}\s[0-9]{4}$)|([0-9]{4}-[0-9]{4}-[0-9]{4}$)/;
    return expr.test(aadhaar);
  };
  const validatePAN = (pan) => {
    const regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    return regex.test(pan.toUpperCase());
  };
  
  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];
  
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreviews({
          ...imagePreviews,
          [name]: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  
    setFormData({
      ...formData,
      [name]: file,
    });
  };

  const handleRemoveImage = (name) => {
    setImagePreviews({
      ...imagePreviews,
      [name]: null,
    });
    setFormData({
      ...formData,
      [name]: null,
    });
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleDeleteLoan = (index) => {
    // Create a new array excluding the loan entry at the specified index
    const updatedEntries = loanEntries.filter((_, i) => i !== index);
    // Update the state with the new array
    setLoanEntries(updatedEntries);
    const newTotalLoanAmount = updatedEntries.reduce((total, entry) => {
      // Convert to number or default to 0 if invalid
      const loanAmount = parseFloat(entry.loanAmount) || 0;
      return total + loanAmount;
    }, 0);
    
    // Update the total loan amount state
    setTotalLoanAmount(newTotalLoanAmount);
  };

  return (
    <>
    {loading ?  <ClockLoader
      className='clip-loader'
      color="black"
      loading={loading}
      cssOverride={override}
      size={70}
      aria-label="Loading Spinner"
      data-testid="loader"
    /> :
    <>
     

      {dataIsSent && popupResponse && <SuccessModal
             
              popupResponse = {popupResponse}
              message="Data submitted successfully!"
               onClose={handleSuccessClose}/>}
              {dataIsNotSent && <ErrorModal 
              ErrorResponse = {ErrorResponse}
              isOpen={dataIsNotSent}
              message="An error occurred. Please try again."
              onClose={handleErrorClose}
              />}
    <div className="tirumala-dc-form" id="data-form">
    <img className='tirumala-page-image' src={logoImage} alt="tirumala Logo" />
      <form className={`data-form-main`} onSubmit={handleSubmit}>
    
        {currentPage === 1 && (
          <div className="first-part">
            <label>
              Customer ID:
              <input
                type="text"
                name="customerId"
                value={formData.customerId}
                onChange={handleChange}
              />
              {errors.customerId && <span className="error">{errors.customerId}</span>}
            </label>
            <label>
              First Name:
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
              />
              {errors.firstName && <span className="error">{errors.firstName}</span>}
            </label>
            <label>
              Last Name:
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
              />
              {errors.lastName && <span className="error">{errors.lastName}</span>}
            </label>
            <label>
              DOB (DD-MM-YYYY):
              <input
                type="date"
                name="dob"
                value={formData.dob}
                onChange={handleDobChange}
              />
              {errors.dob && <span className="error">{errors.dob}</span>}
            </label>
            <label>
              Age:
              <input
                type="text"
                name="age"
                value={formData.age}
                readOnly
              />
              {errors.age && <span className="error">{errors.age}</span>}
              {ageLimitExceed && <span className="error">Max age allowed is 65</span>}
            </label>
            <label>
              Gender:
              <select
                name="gender"
                value={formData.gender}
                onChange={handleChange}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
              {errors.gender && <span className="error">{errors.gender}</span>}
            </label>
            <label>
              Address:
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleChange}
              />
              {errors.address && <span className="error">{errors.address}</span>}
            </label>
            <label>
              Pincode:
              <input
                type="number"
                name="pincode"
                value={formData.pincode}
                onChange={handleChange}
              />
              {errors.pincode && <span className="error">{errors.pincode}</span>}
            </label>
            <label>
              State:
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleChange}
              />
              {errors.state && <span className="error">{errors.state}</span>}
            </label>
            <label>
              Mobile Number:
              <input
                type="number"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
              />
              {errors.mobileNumber && <span className="error">{errors.mobileNumber}</span>}
            </label>
            <label>
              Email Id:
              <input
                type="email"
                name="emailId"
                value={formData.emailId}
                onChange={handleChange}
              />
              {errors.emailId && <span className="error">{errors.emailId}</span>}
            </label>
            <label>
              Aadhar front Image (Optional):
              <input
                type="file"
                name="aadharFrontImage"
                accept="image/*"
                onChange={handleFileChange}
              />
              {imagePreviews.aadharFrontImage && (
                <div className="image-preview">
                  <img src={imagePreviews.aadharFrontImage} alt="Aadhar Card" />
                  <p>Image uploaded</p>
                  <button type="button" onClick={() => handleRemoveImage('aadharFrontImage')}>Change Image</button>
                </div>
              )}
            </label>
            <label>
              Aadhar Back Image (Optional):
              <input
                type="file"
                name="aadharBack"
                accept="image/*"
                onChange={handleFileChange}
              />

              {imagePreviews.aadharBack && (
                <div className="image-preview">
                  <img src={imagePreviews.aadharBack} alt="Aadhar Card" />
                  <p>Image uploaded</p>
                  <button type="button" onClick={() => handleRemoveImage('aadharBack')}>Change Image</button>
                </div>
              )}
            </label>
            <label>
              PAN Card Image (Optional):
              <input
                type="file"
                name="panImage"
                accept="image/*"
                onChange={handleFileChange}
              />
              {imagePreviews.panImage && (
                <div className="image-preview">
                  <img src={imagePreviews.panImage} alt="PAN Card" />
                  <p>Image uploaded</p>
                  <button type="button" onClick={() => handleRemoveImage('panImage')}>Change Image</button>
                </div>
              )}
            </label>
          </div>
        )}

        {currentPage === 2 &&  (
          <div className="second-part">
            <label>
              Member Aadhar No.:
              <input
                type="number"
                name="memberAadharNo"
                value={formData.memberAadharNo}
                onChange={handleChange}
              />
              {errors.memberAadharNo && <span className="error">{errors.memberAadharNo}</span>}
            </label>
            <label>
              Member PAN Card:
              <input
                type="text"
                name="memberPANCard"
                value={formData.memberPANCard}
                onChange={handleChange}
              />
              {errors.memberPANCard && <span className="error">{errors.memberPANCard}</span>}
            </label>
            
            <label>
              Nominee Details Name:
              <input
                type="text"
                name="nomineeDetailsName"
                value={formData.nomineeDetailsName}
                onChange={handleChange}
              />
              {errors.nomineeDetailsName && <span className="error">{errors.nomineeDetailsName}</span>}
            </label>
            <label>
              Nominee Relationship:
              <select
        name='nomineeRelationship'
        value={formData.nomineeRelationship}
        onChange={handleChange} // Correctly pass e and index
      >
        <option value="">Select Relationship</option>
        <option value="son">Son</option>
        <option value="daughter">Daughter</option>
        <option value="father">Father</option>
        <option value="mother">Mother</option>
        <option value="spouse">Spouse</option>
        <option value="brother">Brother</option>
        <option value="sister">Sister</option>
      </select>
              {errors.nomineeRelationship && <span className="error">{errors.nomineeRelationship}</span>}
            </label>
            <label>
              Nominee Aadhar No.:
              <input
                type="number"
                name="nomineeAadharNo"
                value={formData.nomineeAadharNo}
                onChange={handleChange}
              />
              {errors.nomineeAadharNo && <span className="error">{errors.nomineeAadharNo}</span>}
            </label>
            <label>
              Nominee Pan No.:
              <input
                type="text"
                name="nomineePanNo"
                value={formData.nomineePanNo}
                onChange={handleChange}
              />
              {errors.nomineePanNo && <span className="error">{errors.nomineePanNo}</span>}
            </label>
            <label>
              Member Occupation:
              <input
                type="text"
                name="memberOccupation"
                value={formData.memberOccupation}
                onChange={handleChange}
              />
              {errors.memberOccupation && <span className="error">{errors.memberOccupation}</span>}
            </label>
            <div className='loan-amount-section'>
            {loanEntries && loanEntries.map((entry, index) => (
  <div key={index} className='loan-amount-section-first'>
    <label>
      Loan Amount:
      <input
        type="text"
        name={`loanAmount_${index}`}
        value={entry.loanAmount}
        onChange={(e) => handleLoanChange(e, index)} // Correctly pass e and index
      />
      {errors[`loanAmount_${index}`] && <span className="error">{errors[`loanAmount_${index}`]}</span>}
    </label>
    <label>
      Loan Sanction Date:
      <input
        type="date"
        name={`loanSanctionDate_${index}`}
        value={entry.loanSanctionDate}
        onChange={(e) => handleLoanChange(e, index)} // Correctly pass e and index
      />
      {errors[`loanSanctionDate_${index}`] && <span className="error">{errors[`loanSanctionDate_${index}`]}</span>}
    </label>
    <label>
      Loan End Date:
      <input
        type="date"
        name={`loanEndDate_${index}`}
        value={entry.loanEndDate}
        onChange={(e) => handleLoanChange(e, index)} // Correctly pass e and index
      />
      {errors[`loanEndDate_${index}`] && <span className="error">{errors[`loanEndDate_${index}`]}</span>}
    </label>
    <label>
      Loan Type:
      <select
        name={`loanType_${index}`}
        value={entry.loanType}
        onChange={(e) => handleLoanChange(e, index)} // Correctly pass e and index
      >
        <option value="">Select Loan Type</option>
        <option value="Personal">Personal</option>
        <option value="LAP">LAP</option>
        <option value="Vehicle">Vehicle</option>
        <option value="HomeLoan">Home Loan</option>
        <option value="Others">Others</option>
        <option value="deposit loan">Deposit Loan</option>
        <option value="daily deposits loan">Daily Deposits loan</option>
      </select>
      {errors[`loanType_${index}`] && <span className="error">{errors[`loanType_${index}`]}</span>}
    </label>
    <label>
      Lender Branch:
      <select
        name={`lenderBranch_${index}`}
        value={entry.lenderBranch}
        onChange={(e) => handleLoanChange(e, index)}
      >
        <option value="">Select Lender Branch</option>
        {branchList.map((branch, i) => (
          <option key={i} value={branch}>{branch}</option>
        ))}
      </select>
      {errors[`lenderBranch_${index}`] && <span className="error">{errors[`lenderBranch_${index}`]}</span>}
    </label>
    <label>
      Loan Account Number:
      <input
        type="text"
        name={`loanAccountNumber_${index}`}
        value={entry.loanAccountNumber}
        onChange={(e) => handleLoanChange(e, index)} // Correctly pass e and index
      />
      {errors[`loanAccountNumber_${index}`] && <span className="error">{errors[`loanAccountNumber_${index}`]}</span>}
    </label>
    {index > 0 && (
      <button type="button" className='
      delete-loan' onClick={() => handleDeleteLoan(index)}>Delete</button>
    )}
  </div>
))}
  <div className='loan-amount-section-second'>
    <button onClick={addMoreLoans}>
      Add More Loan Amounts
    </button>
  
  </div>
  <span className='total-loan-amount'>Total loan amount : {totalLoanAmount}</span>
</div>
            
            {/* <label>
              Policy Sum Insured:
              <input
              disabled
                type="number"
                name="cvgSum"
                value={coverage}
              />
            </label> */}
            <label>
              Policy Tenure:
              <select
          value={tenure}
          onChange={(e) => setTenure(e.target.value)}
        >
          {Object.keys(pricingData).map(key => (
            <option key={key} value={key}>{key.replace(/_/g, ' ')}</option>
          ))}
        </select>
      
            </label>
            <label>
              Policy Premium Inc GST:
              <input
                type="text"
                name="premiumIncGST"
                value={formData.premiumIncGST}
                onChange={handleChange}
                disabled={true}
              />
              {errors.premiumIncGST && <span className="error">{errors.premiumIncGST}</span>}
            </label>
          </div>
        )}

        <div className="pagination-controls">
          {currentPage === 1 && (
            <button
            className={areRequiredFieldsFilled() ? '' : 'grey-button-next'}
            type="button"
            onClick={() => handlePageChange(2)}
            disabled={!areRequiredFieldsFilled()}
          >
            Next
          </button>
          )}
          {currentPage === 2 && (
            <>
              <button  type="button" onClick={() => handlePageChange(1)}>Previous</button>
              <button disable = {loading} type="submit">Submit</button>
            </>
          )}
        </div>

      </form>
    </div>
    </>
}
    </>
  );
}
export default TirumalaDC;
